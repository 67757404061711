import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated'; // Importa animações para o select
import Permission from '../api/Permission.js'; // Importa o arquivo Permission.js

const animatedComponents = makeAnimated(); // Inicializa as animações

function CreateRoleModal({ isOpen, onClose, onSubmit }) {
    const [formData, setFormData] = useState({
        name: '',
        display_name: '', // Novo campo
    });
    const [permissions, setPermissions] = useState([]); // Variável para armazenar as permissões
    const [permissionList, setPermissionList] = useState([]); // Array para armazenar as permissões selecionadas

    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                let response = await Permission.list(); // Chama a função list
                response = response.data;
                if (Array.isArray(response)) {
                    setPermissions(response); // Define as permissões no estado
                } else {
                    console.error('Expected an array of permissions');
                }
            } catch (error) {
                console.error('Error fetching permissions:', error);
            }
        };

        fetchPermissions();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handlePermissionChange = (selectedOptions) => {
        const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setPermissionList(selectedValues); // Atualiza a lista de permissões selecionadas
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('formData', formData);
        console.log('permissionList', permissionList);
        if (formData.name !== '' && formData.display_name !== '') {
            onSubmit({ ...formData, permission_list: permissionList });
        }
    };

    if (!isOpen) {
        return null;
    }

    // Mapeia as permissões para o formato esperado pelo Select
    const permissionOptions = permissions.map(permission => ({
        value: permission.name,
        label: permission.display_name,
    }));

    // Customiza os estilos do Select
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #ccc',
            boxShadow: 'none',
            padding: '6px 12px',
            fontSize: '16px',
            borderRadius: '4px',
            backgroundColor: 'white',
            '&:hover': {
                border: '1px solid #888',
            },
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999, // Garante que o menu fique acima de outros elementos
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#007bff' : 'white',
            color: state.isSelected ? 'white' : 'black',
            '&:hover': {
                backgroundColor: '#007bff',
                color: 'white',
            },
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#007bff',
            color: 'white',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: 'white',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: 'white',
            ':hover': {
                backgroundColor: 'red',
                color: 'white',
            },
        }),
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>
                    &times;
                </span>
                <form onSubmit={handleSubmit}>
                    <div className="floating-label">
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder=' '
                            required
                        />
                        <label htmlFor="name" className={formData.name ? 'filled' : ''}>Nome</label>
                    </div>
                    <div className="floating-label">
                        <input
                            type="text"
                            id="display_name" // ID para o novo campo
                            name="display_name" // Nome do novo campo
                            value={formData.display_name} // Valor do novo campo
                            onChange={handleChange} // Função de mudança
                            placeholder=' '
                            required // Torna o campo obrigatório
                        />
                        <label htmlFor="display_name" className={formData.display_name ? 'filled' : ''}>Nome de display</label>
                    </div>
                    <div>
                    <label>Selecionar Permissões</label></div>
                    <div className="floating-label">
                        <Select
                            id="permissions"
                            closeMenuOnSelect={false}
                            components={animatedComponents} // Aplica animação ao select
                            isMulti
                            options={permissionOptions}
                            value={permissionOptions.filter(option => permissionList.includes(option.value))} // Sincroniza o valor selecionado com permissionList
                            onChange={handlePermissionChange}
                            styles={customStyles} // Aplica os estilos personalizados
                        />
                    </div>
                    <input 
                        type="hidden" 
                        id="userId"
                        name="userId"
                        value={formData.userId}
                    />
                    <div className="modal-footer">
                        <button className="btn-primary" type="submit">CRIAR ROLE</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateRoleModal;
