import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faKey } from '@fortawesome/free-solid-svg-icons';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import User from '../api/User.js';
import Permission from '../api/Permission.js';
import Role from '../api/Role.js';
import datagridPT from '../datagrid-pt.js';
import Cookies from 'js-cookie';
import CreateRoleModal from '../components/createRoleModal.js';
import PermissionsModal from '../components/permissionsModal.js';
import ConfirmDialog from '../components/confirmDialog';

function Roles() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [roleData, setRoleData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [selectedRoleName, setSelectedRoleName] = useState(null);
  const [selectedRoleDisplayName, setSelectedRoleDisplayName] = useState(null);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  const columnVisibility = {
    id: false,
  }

  const columns = [
    {
      "field": "id",
      "headerName": "ID",
      "hide": true
    },
    {
      "field": "name",
      "headerName": "Nome",
      "flex": 1,
      "editable": false,
    },
    {
      "field": "display_name",
      "headerName": "Nome de display", // Novo campo
      "flex": 1,
      "editable": false,
    },
    {
      "field": "actions",
      "headerName": "",
      "width": 130,
      "editable": false,
      renderCell: (params) => (
        <div className="table-actions-container">
          <button className="btn-primary-circle" onClick={() => handleOpenPermissionsModal(params.value.roleId, params.value.name, params.value.display_name)}>
            <FontAwesomeIcon icon={faKey} />
          </button>
          <button className="btn-primary-circle btn-delete" onClick={(event) => deleteRoleDialog(params.value.roleId, event)}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    const handleResize = async () => {
      setWindowWidth(window.innerWidth);
    };
    (async () => {
      let csrfToken = Cookies.get('XSRF-TOKEN');
      if (csrfToken == null) window.location.href = process.env.REACT_APP_FULL_DOMAIN + '/';
      await handleResize();
      await getRoleList();
    })();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getRoleList = async () => {
    Role.list()
      .then(async ({ data }) => {
        console.log('roleList', data);
        const transformedData = data.map(role => ({
          id: role.id,
          name: role.display_name,
          display_name: role.display_name, // Adiciona display_name
          actions: { roleId: role.id, name: role.name, display_name: role.display_name },
        }));
        setRoleData({
          columns: columns,
          rows: transformedData,
          initialState: { sorting: { sortModel: [{ field: 'id', sort: 'asc' }] } },
          pageSize: 10,
          page: 0,
        });
      }).catch(err => {
        console.log('error on getting roles list:', err);
        return;
      });
  };

  const handleOpenCreateModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenPermissionsModal = (roleId, name, display_name) => {
    setSelectedRoleId(roleId);
    setSelectedRoleName(name);
    setSelectedRoleDisplayName(display_name);
    setIsPermissionsModalOpen(true);
  };

  const handleClosePermissionsModal = () => {
    setIsPermissionsModalOpen(false);
  };

  const handleCreateFormSubmit = (formData) => {
    const dataArr = {
      role: formData.name,
      name: formData.name,
      permissions: formData.permission_list,
      display_name: formData.display_name, // Envia o display_name
    };

    Role.create(dataArr)
      .then(({ data }) => {
        console.log('createRoleResponse', data);
        handleCloseModal();
        getRoleList();
      }).catch(err => {
        console.log('error on creating role:', err);
        return;
      });
  };

  const deleteRoleDialog = (id, event) => {
    event.stopPropagation();
    setConfirmDeleteDialogOpen(true);
    setDeleteId(id);
  };

  const deleteRole = async () => {
    Role.delete(deleteId)
      .then(({ data }) => {
        console.log('deleteRoleResponse', data);
        getRoleList();
      }).catch(err => {
        console.log('error on deleting role:', err);
        return;
      });
  };

  return (
    <div className="settings-container">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="btn-create-appointment">
              <button className="btn-primary" onClick={handleOpenCreateModal}>
                <FontAwesomeIcon icon={faPlus} /> CRIAR ROLE
              </button>
            </div>
            {roleData && roleData.rows.length > 0 &&
              <div className="table-container">
                <DataGrid
                  {...roleData}
                  localeText={datagridPT}
                  autoHeight
                  initialState={{
                    ...roleData.initialState,
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  columnVisibilityModel={columnVisibility}
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  disableSelectionOnClick
                />
              </div>
            }
          </div>
        </div>
      </div>
      {isModalOpen && (
        <CreateRoleModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSubmit={handleCreateFormSubmit}
        />
      )}
      {isPermissionsModalOpen && (
        <PermissionsModal
          isOpen={isPermissionsModalOpen}
          onClose={handleClosePermissionsModal}
          roleId={selectedRoleId}
          roleName={selectedRoleName}
          roleDisplayName={selectedRoleDisplayName}
        />
      )}
      <ConfirmDialog
        isOpen={confirmDeleteDialogOpen}
        message="Tem certeza que deseja eliminar este role?"
        onConfirm={deleteRole}
        onCancel={() => setConfirmDeleteDialogOpen(false)}
      />
    </div>
  );
}

export default Roles;
