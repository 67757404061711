import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { requestNotificationPermission, getToken } from './firebase';
import './global.js';
import { SessionProvider } from './SessionContext';

// Import pages
import SignIn from './pages/sign-in.js';
import Menu from './components/menu.js';
import Dashboard from './pages/dashboard.js';
import Trips from './pages/trips.js';
import Mytrips from './pages/mytrips.js';
import AllTrips from './pages/alltrips.js';
import Destinies from './pages/destinies.js';
import Settings from './pages/settings.js';
import VerifyEmail from './pages/verify-email.js';
import VerifyEmailMicrosoft from './pages/verify-email-microsoft.js';
import Tickets from './pages/tickets.js';
import Roles from './pages/roles.js';
import Notifications from './pages/notifications.js';
import ForgotPassword from './pages/forgot-password.js'

// Import components
import './App.css';

function App() {
  useEffect(() => {
    const initNotifications = async () => {
      await requestNotificationPermission();
    };

    initNotifications();
  }, []);

  return (
    <div className="main-screen">
      <SessionProvider>
        <Router>
          <Routes>
            <Route path="/" element={<SignIn />} />
            {/*<Route path="/verify-email/:id/:token" element={<VerifyEmail />} />
            <Route path="/verify-email-microsoft" element={<VerifyEmailMicrosoft />} />*/}
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/dashboard" element={<><Menu active='dashboard' /><Dashboard /></>} />
            <Route path="/trips" element={<><Menu active='trips' /><Trips /></>} />
            <Route path="/mytrips" element={<><Menu active='mytrips' /><Mytrips /></>} />
            <Route path="/all-trips" element={<><Menu active='all-trips' /><AllTrips /></>} />
            <Route path="/destinies" element={<><Menu active='destinies' /><Destinies /></>} />
            <Route path="/settings" element={<><Menu active='settings' /><Settings /></>} />
            <Route path="/tickets" element={<><Menu active='tickets' /><Tickets /></>} />
            <Route path="/roles" element={<><Menu active='roles' /><Roles /></>} />
            <Route path="/notifications" element={<><Menu active='notifications' /><Notifications /></>} />
          </Routes>
        </Router>
      </SessionProvider>
    </div>
  );
}

export default App;
