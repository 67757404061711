import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../resources/logo.png';
import Cookies from 'js-cookie';
import { TailSpin } from 'react-loader-spinner';
import User from '../api/User.js'
import { useMsal } from "@azure/msal-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouseLock, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';


function SignIn() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [isButtonActive, setIsButtonActive] = useState(false);
    const [isLogged, setIsLogged] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { instance, accounts, inProgress } = useMsal();

    const isAuthenticated = accounts.length > 0;

    useEffect(() => {
        let csrfToken = Cookies.get('XSRF-TOKEN');

        if(csrfToken == undefined)
            csrfToken = null

        if (csrfToken == null) setIsLogged(false);
        else window.location.href = process.env.REACT_APP_FULL_DOMAIN+'/dashboard';
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const encodedPassword = btoa(password);

            User.login(username, encodedPassword, null)
                .then(({ data }) => {
                    console.log('loginResponse', JSON.stringify(data));

                    User.getSession()
                        .then(async ({ data }) => {
                            console.log('sessionResponse', data);
                            await setLocalStorage(data);
                            window.location.href = process.env.REACT_APP_FULL_DOMAIN+'/dashboard';
                        }).catch(err => {
                            setIsLoading(false);
                            console.log('error on session:', err)

                            return;
                        });

                }).catch(err => {
                    setIsLoading(false);
                    document.cookie = `XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;
                    setError('Email ou password incorretos');
                    console.log('error on login:', err)

                    return;
                });

        } catch (err) {
            setError('Email ou password incorretos');
        }
    };

    const microsoftLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            
            let account_id = accounts[0]['idTokenClaims']['oid']
            let username = accounts[0]['username']
            
            User.login(username, account_id, 'microsoft')
                .then(({ data }) => {
                    
                    User.getSession()
                        .then(async ({ data }) => {
                            console.log('sessionResponse', data);
                            await setLocalStorage(data);
                            window.location.href = process.env.REACT_APP_FULL_DOMAIN+'/dashboard';
                        }).catch(err => {
                            setIsLoading(false);
                            console.log('error on session:', err)

                            return;
                        });

                }).catch(err => {
                    setIsLoading(false);
                    setError('Conta microsoft inválida');
                    console.log('error on login:', err)

                    return;
                });

        } catch (err) {
            setError('Email ou password incorretos');
        }
    };

    const setLocalStorage = async (data) => {
        localStorage.setItem("userID", data.id);
        localStorage.setItem("name", data.name);
        localStorage.setItem("email", data.email);
    }

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
        updateButtonState(e.target.value, password);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        updateButtonState(username, e.target.value);
    };

    const updateButtonState = (newUsername, newPassword) => {
        setIsButtonActive(newUsername.trim() !== '' && newPassword.trim() !== '');
    };

    

    return (
        <div className="login-container">
            {!isLogged &&
                <div className='login-box'>
                    <img className='login-logo' src={Logo} alt='Conduril' />
                    {/*<form className='login-form' onSubmit={handleLogin}>
                        <div className="floating-label">
                            <input
                                type="email"
                                id="email"
                                value={username}
                                onChange={handleUsernameChange}
                                placeholder=' '
                                required
                            />
                            <label htmlFor="email" className={username ? 'filled' : ''}>Email</label>
                        </div>
                        <div className="floating-label">
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={handlePasswordChange}
                                placeholder=' '
                                required
                            />
                            <label htmlFor="password" className={password ? 'filled' : ''}>Password</label>
                            {error && <p className="error-message">{error}</p>}
                        </div>
                        <p className='login-forgot'><Link to='/forgot-password'>Forgot password?</Link></p>
                        <button className={`btn-primary ${!isButtonActive ? 'blocked-btn' : ''}`} disabled={!isButtonActive} type="submit">
                            {isLoading && 
                                <TailSpin height="20" width="20" color="#ffffff" ariaLabel="loading" />
                            }
                            {!isLoading &&
                                <span>SIGN IN</span>
                            }
                        </button>
                    </form>*/}
                    {!isAuthenticated &&
                        <button className="btn-microsoft-login btn-margin-top" onClick={() => instance.loginPopup()}><FontAwesomeIcon icon={faHouseLock} /> Login com Microsoft</button>
                    }
                    {isAuthenticated &&
                        <form onSubmit={microsoftLogin}>
                            <div className="div-microsoft-title">
                                Microsoft
                            </div>
                            <button className="btn-microsoft-login" type="submit"><FontAwesomeIcon icon={faHouseLock} /> {accounts[0]['username']}</button>	
                        </form>
                    }
                    {isAuthenticated &&
                        <button className="btn-microsoft-logout btn-margin-top" onClick={() => instance.logout()}><FontAwesomeIcon icon={faArrowCircleLeft} /> Logout</button>
                    }
                </div>
            }
        </div>
    );
}

export default SignIn;
