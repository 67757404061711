import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faBan, faTrash, faPlus, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import User from '../api/User.js';
import Car from '../api/Car.js';
import Role from '../api/Role.js';
import datagridPT from '../datagrid-pt';
import CreateUserModal from '../components/createUserModal';
import Cookies from 'js-cookie';
import { TailSpin } from 'react-loader-spinner';
import { useMsal } from "@azure/msal-react";
import { faHouseLock, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import ConfirmDialog from '../components/confirmDialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';

function Settings() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isAdmin, setIsAdmin] = useState(null);
  const [usersData, setUsersData] = useState(null);
  const [rolesList, setRolesList] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateFormData, setUpdateFormData] = useState(null);
  const [carId, setCarId] = useState(null);
  const [confirmDeactivateDialog, setConfirmDeactivateDialog] = useState(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [deactivateId, setDeactivateId] = useState(0);
  const [deleteId, setDeleteId] = useState(0);
  const [showActive, setChecked] = useState(true);
  const { instance, accounts, inProgress } = useMsal();

  const isAuthenticated = accounts.length > 0;

  const columnVisibility = {
  }

  const columns = [
    {
      "field": "code",
      "headerName": "Código",
      "flex": 1,
    },
    {
      "field": "email",
      "headerName": "Email",
      "flex": 1,
      "editable": false,
    },
    {
      "field": "name",
      "headerName": "Name",
      "flex": 1,
      "editable": false,
    },
    {
      "field": "actions",
      "headerName": "",
      "width": 111,
      "editable": false,
      renderCell: (params) => (
        <div className="table-actions-container">
          <button className="btn-primary-circle" onClick={() => handleOpenEditModal(params.value.userId)}>
            <FontAwesomeIcon icon={faPencil} />
          </button>
          {showActive && 
            <button className="btn-primary-circle btn-delete" onClick={(event) => deactivateUserDialog(params.value.userId, event)}>
              <FontAwesomeIcon icon={faBan} />
            </button>
          }
          {!showActive &&
            <button className="btn-primary-circle btn-success" onClick={(event) => reactivateUser(params.value.userId, event)}>
              <FontAwesomeIcon icon={faRotateRight} />
            </button>
          }
          {!showActive &&
            <button className="btn-primary-circle btn-delete" onClick={(event) => deleteUserDialog(params.value.userId, event)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          }
        </div>
      ),
    },
  ]

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }


  useEffect(() => {
    const handleResize = async () => {
      setWindowWidth(window.innerWidth);
    }
    (async () => {
      let csrfToken = Cookies.get('XSRF-TOKEN');
      if (csrfToken == null) window.location.href = process.env.REACT_APP_FULL_DOMAIN + '/';
      await handleResize();
      User.getSession()
        .then(async ({ data }) => {
            console.log('sessionResponse', data);
            const hasAdminRole = data.roles.some(role => role.name === "super");
            setIsAdmin(hasAdminRole);
            if (hasAdminRole) {
              //LOAD ADMIN DATA
              await getUserList();
              await getRolesList();
            }
            else {
              //LOAD USER DATA
              await getMyUserInfo();
            }
        }).catch(err => {
            console.log('error on session:', err)

            return;
        });
    })();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (userInfo != null) setIsModalOpen(true);
  }, [userInfo]);


  const getUserList = async () => {
    User.list({is_active: Number(showActive)})
      .then(async ({ data }) => {
        console.log('userList', data);
        const transformedData = data.map(user => ({
          id: user.id,
          code: user.code,
          email: user.email,
          microsoftIdentifier: user.microsoft_identifier,
          name: user.name,
          actions: { userId: user.id },
        }));
        setUsersData({
          columns: columns,
          rows: transformedData,
          initialState: { sorting: { sortModel: [{ field: 'id', sort: 'asc' }] } },
          pageSize: 10,
          page: 0,
        });
      }).catch(err => {
        console.log('error on getting users list:', err)

        return;
      });
  };

  const getRolesList = async () => {
    Role.list()
      .then(async ({ data }) => {
        console.log('roleList', data);
        const transformedData = data.map(role => ({
          id: role.id,
          name: role.name,
          display_name: role.display_name,
        }));
        setRolesList(transformedData);
      }).catch(err => {
        console.log('error on getting roles list:', err)

        return;
      });
  };

  const getUserInfo = async (userId) => {
    User.get(userId)
      .then(async ({ data }) => {
        console.log('userInfo', data);
        const dataUser = data;
        Car.get(userId)
          .then(async ({ data }) => {
            console.log('carInfo', data);
            setUserInfo({
              id: dataUser.id,
              email: dataUser.email,
              code: dataUser.code,
              name: dataUser.name,
              username: dataUser.username,
              phone: dataUser.phone_number,
              role: dataUser.roles[0],
              carBrand: data.marca,
              carModel: data.modelo,
              carPlate: data.matricula,
            })
            setCarId(data.id);
          }).catch(err => {
            console.log('error on getting car:', err)
            setUserInfo({
              id: dataUser.id,
              email: dataUser.email,
              code: dataUser.code,
              name: dataUser.name,
              username: dataUser.username,
              phone: dataUser.phone_number,
              role: dataUser.roles[0],
              carBrand: '',
              carModel: '',
              carPlate: '',
            })
            return;
          });
      }).catch(err => {
        console.log('error on getting user info:', err)

        return;
      });
  };

  const handleOpenCreateModal = () => {
    setIsModalOpen(true);
  };

  const handleOpenEditModal = async (userId) => {
    await getUserInfo(userId);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleRemoveMicrosoftAccount = async (e) => {
    e.preventDefault()
    await User.removeMicrosoftAccount(updateFormData.userId).then((resp)  =>  {
      window.location.reload()
    })
  };

  const handleRegisterMicrosoftAccount = async (e) => {
    await handleUpdateMyUserInfo(e)
    window.location.reload()
  };

  const handleCreateFormSubmit = (formData) => {

    var dataArr = {};
    if (formData.password == '') {
      dataArr = {
        email: formData.email,
        code: formData.code,
        name: formData.name,
        username: formData.username,
        phone_number: formData.phone,
        roles: [formData.role],
        is_active: true,
      }
    }
    else {
      const encodedPassword = btoa(formData.password);
      console.log('password', formData.password);
      console.log('encodedPassword', encodedPassword);
      dataArr = {
        email: formData.email,
        name: formData.name,
        code: formData.code,
        username: formData.username,
        password: encodedPassword,
        phone_number: formData.phone,
        roles: [formData.role],
        is_active: true,
      }
    }

    if (formData.userId != '') {
      User.update(formData.userId, dataArr)
        .then(({ data }) => {
          console.log('updateUserResponse', data);
          if (formData.carBrand != '' && formData.carBrand != '' && formData.carBrand != '') {
            const carDataArr = {
              user_id: formData.userId,
              marca: formData.carBrand,
              modelo: formData.carModel,
              matricula: formData.carPlate
            }
            if (carId != null) {
              Car.update(carId, carDataArr)
                .then(({ data }) => {
                  console.log('updateCarResponse', data);
                  handleCloseModal();
                  getUserList();
                }).catch(err => {
                  console.log('error on updating car:', err)

                  return;
                });
            }
            else {
              Car.create(carDataArr)
                .then(({ data }) => {
                  console.log('createCarResponse', data);
                  handleCloseModal();
                  getUserList();
                }).catch(err => {
                  console.log('error on creating car:', err)
                  handleCloseModal();
                  getUserList();
                  return;
                });
            }
          }
          else {
            handleCloseModal();
            getUserList();
          }

        }).catch(err => {
          console.log('error on updating user:', err)

          return;
        });
    }
    else {
      User.create(dataArr)
        .then(({ data }) => {
          console.log('createUserResponse', data);
          if (formData.carBrand != '' && formData.carBrand != '' && formData.carBrand != '') {
            console.log('userId', data.id);
            const carDataArr = {
              user_id: data.id,
              marca: formData.carBrand,
              modelo: formData.carModel,
              matricula: formData.carPlate
            }
            Car.create(carDataArr)
              .then(({ data }) => {
                console.log('createCarResponse', data);
                handleCloseModal();
                getUserList();
              }).catch(err => {
                console.log('error on creating car:', err)

                return;
              });
          }
          else {
            handleCloseModal();
            getUserList();
          }
        }).catch(err => {
          console.log('error on creating user:', err)

          return;
        });
    }
  };

  const deactivateUserDialog = (id, event) => {
    event.stopPropagation();
    setConfirmDeactivateDialog(true);
    setDeactivateId(id);
  };

  const deleteUserDialog = (id, event) => {
    event.stopPropagation();
    setConfirmDeleteDialog(true);
    setDeleteId(id);
  };
  
  const reactivateUser = (id, event) => {
    event.stopPropagation();
    User.reactivate(id).then(() => {
      getUserList(false)
    })
  }

  const deactivateUser = async () => {
    setConfirmDeactivateDialog(false)
    User.deactivateUser(deactivateId)
      .then(({ data }) => {
        console.log('deactivateUserResponse', data);
        setDeactivateId(false)
        getUserList();
      }).catch(err => {
        console.log('error on deleting user:', err)

        return;
      });
  };

  const deleteUser = async () => {
    setConfirmDeleteDialog(false)
    User.delete(deleteId)
      .then(({ data }) => {
        console.log('deletedUserResponse', data);
        setDeleteId(false)
        getUserList();
      }).catch(err => {
        console.log('error on deleting user:', err)

        return;
      });
  };


  // REGULAR USER FUNCTIONS

  const getMyUserInfo = async () => {
    User.getSession()
      .then(async ({ data }) => {
        console.log('sessionResponse', data);
        const dataUser = data;
        Car.get(data.id)
          .then(async ({ data }) => {
            console.log('carInfo', data);
            setUpdateFormData({
              email: dataUser.email,
              name: dataUser.name,
              code: dataUser.code,
              username: dataUser.username,
              phone: dataUser.phone_number,
              role: dataUser.roles[0].display_name,
              carBrand: data.marca,
              carModel: data.modelo,
              carPlate: data.matricula,
              userId: dataUser.id,
              microsoftIdentifier: dataUser.microsoft_identifier,
              code: dataUser.code,
            })
            setCarId(data.id);
          }).catch(err => {
            console.log('error on getting car:', err)
            setUpdateFormData({
              email: dataUser.email,
              name: dataUser.name,
              code: dataUser.code,
              username: dataUser.username,
              phone: dataUser.phone_number,
              role: dataUser.roles[0].display_name,
              carBrand: '',
              carModel: '',
              carPlate: '',
              userId: dataUser.id,
              microsoftIdentifier: dataUser.microsoft_identifier,
              code: dataUser.code,
            })
            return;
          });
        
      }).catch(err => {
        console.log('error on getting session:', err)

        return;
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData({
      ...updateFormData,
      [name]: value,
    });
  };


  const handleUpdateMyUserInfo = async (e) => {
    e.preventDefault();
    console.log('formData', updateFormData);

    let account_id = accounts.length > 0 ? accounts[0]['idTokenClaims']['oid'] : null
    

    if (updateFormData.email != '' && updateFormData.code != '' && updateFormData.name != '' && updateFormData.username != '' && updateFormData.phone != '' && updateFormData.role != '') {
      var dataArr = {};
      if (updateFormData.password == '') {
        dataArr = {
          email: updateFormData.email,
          code: updateFormData.code,
          name: updateFormData.name,
          username: updateFormData.username,
          phone_number: updateFormData.phone,
          is_active: true,
          microsoft_identifier: account_id
        }
      }
      else {
        const encodedPassword = btoa(updateFormData.password);
        dataArr = {       
          email:                updateFormData.email,
          code:                 updateFormData.code,
          name:                 updateFormData.name,
          username:             updateFormData.username,
          password:             encodedPassword,
          phone_number:         updateFormData.phone,
          is_active:            true,
          microsoft_identifier: account_id
        }
      }

      await User.update(localStorage.getItem('userID'), dataArr)
        .then(async ({ data }) => {
          console.log('updateUserResponse', data);
          if (updateFormData.carBrand != '' && updateFormData.carBrand != '' && updateFormData.carBrand != '') {
            const carDataArr = {
              user_id: updateFormData.userId,
              marca: updateFormData.carBrand,
              modelo: updateFormData.carModel,
              matricula: updateFormData.carPlate
            }
            if (carId != null) {
              await Car.update(carId, carDataArr)
                .then(({ data }) => {
                  console.log('updateCarResponse', data);
                  handleCloseModal();
                  getUserList();
                }).catch(err => {
                  console.log('error on updating car:', err)

                  return;
                });
            }
            else {
              await Car.create(carDataArr)
                .then(({ data }) => {
                  console.log('createCarResponse', data);
                  handleCloseModal();
                  getUserList();
                }).catch(err => {
                  console.log('error on creating car:', err)

                  return;
                });
            }
          }
          else {
            handleCloseModal();
            getUserList();
          }

        }).catch(err => {
          console.log('error on updating user:', err)

          return;
        });
    }
  };

  const handleActiveUsersSwitch = (e)  =>  {
    setChecked(e.target.checked)
  }

  useEffect(() => {
    getUserList()
  }, [showActive]);

  return (
    <div className="settings-container">
      <div className="container">
        {isAdmin == null &&
          <div className="loading-container">
            <TailSpin height="80" width="80" color="#0c1534" ariaLabel="loading" />
          </div>
        }
        {isAdmin != null && isAdmin &&
          <div className="row">
            <div className="col-md-12">
              <div className="btn-create-appointment">
                <button className="btn-primary" onClick={handleOpenCreateModal}>
                  <FontAwesomeIcon icon={faPlus} /> CRIAR UTILIZADOR
                </button>
              </div>
              <div className="col-md-12">
              <FormControl component="fieldset" variant="standard">
                <FormControlLabel control={
                  <Switch checked={showActive} onChange={handleActiveUsersSwitch} name="showActive" />
                } label="Mostrar ativos" labelPlacement="start" />
              </FormControl>
              </div>
              {usersData && usersData.rows.length > 0 ? (
                <div className="table-container">
                  <DataGrid
                    {...usersData}
                    localeText={datagridPT}
                    autoHeight
                    initialState={{
                      ...usersData.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    columnVisibilityModel={columnVisibility}
                    slots={{ toolbar: CustomToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    disableSelectionOnClick
                  />
                </div>
              ) : (
                <div className="no-data-message">
                  Sem dados a mostrar
                </div>
              )
              }
            </div>
          </div>
        }
        {isAdmin != null && !isAdmin && updateFormData != null &&
          <form onSubmit={handleUpdateMyUserInfo} className="edit-profile-form">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-separator" style={{ marginTop: 0 }}>Perfil</div>
                </div>
                <div className="col-md-6">
                  <div className="floating-label">
                    <input
                      type="text"
                      id="code"
                      name="code"
                      value={updateFormData.code}
                      onChange={handleChange}
                      placeholder=' '
                      required
                    />
                    <label htmlFor="date" className={updateFormData.code ? 'filled' : ''}>Código</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="floating-label">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={updateFormData.email}
                      placeholder=' '
                      required
                      disabled
                    />
                    <label htmlFor="date" className={updateFormData.email ? 'filled' : ''}>Email</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="floating-label">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={updateFormData.name}
                      onChange={handleChange}
                      placeholder=' '
                      required
                    />
                    <label htmlFor="name" className={updateFormData.name ? 'filled' : ''}>Nome</label>
                  </div>
                </div>
                {/*<div className="col-md-6">
                  <div className="floating-label">
                    <input
                      type="text"
                      id="username"
                      name="username"
                      value={updateFormData.username}
                      onChange={handleChange}
                      placeholder=' '
                      required
                    />
                    <label htmlFor="username" className={updateFormData.username ? 'filled' : ''}>Username</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="floating-label">
                    <input
                      type="password"
                      id="password"
                      name="password"
                      value={updateFormData.password}
                      onChange={handleChange}
                      placeholder=' '
                    />
                    <label htmlFor="password" className={updateFormData.password ? 'filled' : ''}>Password</label>
                  </div>
                </div>*/}
                <div className="col-md-6">
                  <div className="floating-label">
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={updateFormData.phone}
                      onChange={handleChange}
                      placeholder=' '
                      required
                    />
                    <label htmlFor="phone" className={updateFormData.phone ? 'filled' : ''}>Telemóvel</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="floating-label">
                    <input
                      type="text"
                      id="role"
                      name="role"
                      value={updateFormData.role}
                      placeholder=' '
                      required
                      disabled
                    />
                    <label htmlFor="role" className={updateFormData.role ? 'filled' : ''}>Role</label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-separator">Carro</div>
                </div>
                <div className="col-md-6">
                  <div className="floating-label">
                    <input
                      type="text"
                      id="carBrand"
                      name="carBrand"
                      value={updateFormData.carBrand}
                      onChange={handleChange}
                      placeholder=' '
                    />
                    <label htmlFor="carBrand" className={updateFormData.carBrand ? 'filled' : ''}>Marca</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="floating-label">
                    <input
                      type="text"
                      id="carModel"
                      name="carModel"
                      value={updateFormData.carModel}
                      onChange={handleChange}
                      placeholder=' '
                    />
                    <label htmlFor="carModel" className={updateFormData.carModel ? 'filled' : ''}>Modelo</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="floating-label">
                    <input
                      type="text"
                      id="carPlate"
                      name="carPlate"
                      value={updateFormData.carPlate}
                      onChange={handleChange}
                      placeholder=' '
                    />
                    <label htmlFor="carPlate" className={updateFormData.carPlate ? 'filled' : ''}>Matrícula</label>
                  </div>
                </div>
                {/*<div className="col-md-12">
                  <div className="form-separator">Microsoft</div>
                </div>
                <div className="col-md-5">                  
                  {!isAuthenticated && !isAdmin &&
                      <button className="btn-microsoft-login btn-margin-top" onClick={() => instance.loginPopup()}><FontAwesomeIcon icon={faHouseLock} /> Login com Microsoft</button>
                  }
                  {isAuthenticated && !isAdmin &&
                      <button className="btn-microsoft-login" onClick={handleRegisterMicrosoftAccount}><FontAwesomeIcon icon={faHouseLock} /> {accounts[0]['username']}</button>	
                  }
                </div>
                <div className="col-md-4">
                  {isAuthenticated && !isAdmin &&
                      <button className="btn-microsoft-logout" onClick={() => instance.logout()}><FontAwesomeIcon icon={faArrowCircleLeft} /> Logout</button>
                  }
                </div>
                <div className="col-md-4">
                  {updateFormData.microsoftIdentifier != null &&
                      <button onClick={handleRemoveMicrosoftAccount} className="btn-microsoft-remove btn-margin-top"> Remover</button>
                  }
                </div>
                <div className="col-md-12">
                  <div className="btn-container">
                    <button className="btn-primary" type="submit">GRAVAR</button>
                  </div>
                </div>*/}
              </div>
            </div>
          </form>
        }
      </div>
      {
        isModalOpen && rolesList != null && (
          <CreateUserModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            onSubmit={handleCreateFormSubmit}
            roles={rolesList}
            userInfo={userInfo}
          />
        )
      }
      <ConfirmDialog
        isOpen={confirmDeactivateDialog}
        message="Tem certeza que deseja desativar este utilizador?"
        onConfirm={deactivateUser}
        onCancel={() => setConfirmDeactivateDialog(false)}
      />
      <ConfirmDialog
        isOpen={confirmDeleteDialog}
        message="Tem certeza que deseja eliminar este utilizador?"
        onConfirm={deleteUser}
        onCancel={() => setConfirmDeleteDialog(false)}
      />
    </div >
  );
}

export default Settings;
